import React from "react"
import { AuthenticatedResourcesStrings } from "../types/enums/authenticated-resource-strings"
import { getResourceStringByid } from "../utils/resource-string-helper"
import { useAllKontentResourceStringData } from "../graphql-static/use-resource-strings"
import Logo from "../images/horizon-icon.svg"

// @ts-ignore
export default function NotFound(props) {
  const resourceStrings = useAllKontentResourceStringData()

  return (
    <>
      <main className="min-h-full px-4 py-16 bg-white sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <img
          src={Logo}
          className="w-auto h-32 mx-auto mt-8 mb-24"
          alt="Horizon Logo"
        />
        <div className="mx-auto max-w-max sm:flex">
          <p className="text-4xl font-bold tracking-tight sm:text-5xl">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl">
                {getResourceStringByid(
                  resourceStrings,
                  AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_ERRORHANDLING_404_TITLE
                )}
              </h1>
              <p className="mt-1 text-base max-w-[335px] text-gray-500">
                {getResourceStringByid(
                  resourceStrings,
                  AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_ERRORHANDLING_404_MESSAGE
                )}
              </p>
            </div>
            <div className="flex mt-10 space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <a
                href={getResourceStringByid(
                  resourceStrings,
                  AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_ERRORHANDLING_404_HREF
                )}
                className="inline-flex items-center px-12 py-2 text-sm font-medium text-white border-transparent rounded-full bg-horizonred hover:bg-horizonhover hover:text-grey-300"
              >
                {getResourceStringByid(
                  resourceStrings,
                  AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_ERRORHANDLING_404_BUTTON
                )}
              </a>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
